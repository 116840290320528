import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { BrowserRouter as Router, Route, useParams } from 'react-router-dom';
import { Parser } from "html-to-react";

import PropTypes from "prop-types";


const BlogDetails = (props) => {
  const [articles, setArticles] = useState([]);
	const [loading, setLoading] = useState(true);
	const [page, setPage] = useState(1);
	const [totalResults, setTotalResults] = useState(0);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const paramValue = urlParams.get('id');
  
  
	const updateNews = async () => {
		props.setProgress(30);
		//const url = `/api/berita/web?id=`+paramValue;
    const url = `https://webapi.satudata.sulbarprov.go.id/berita/web?id=`+paramValue;
	
		setLoading(true);
		let data = await fetch(url,{
      method: "get",
		mode:'cors'
    });
		let parseData = await data.json();
		setArticles(parseData.data);
		setTotalResults(parseData.totalResults);
		setLoading(false);
		props.setProgress(100);
   
		};

	  useEffect(() => {
			updateNews();
			
		}, []);
    const fetchMoreData = async () => {
      //const url = `/api/berita/web?id=`+paramValue;
      const url = `https://webapi.satudata.sulbarprov.go.id/berita/web?id=`+paramValue;
      setPage(page + 1);
      let data = await fetch(url,{
        method: "get",
      mode:'cors'
      });
      let parseData = await data.json();
      setArticles(articles.concat(parseData.articles));
      setTotalResults(parseData.totalResults);
    }; 
    console.log(articles);
  return (
    <div className="postbox__area pt-130 pb-100">
    <div className="container">
      <div className="row">
        <div className="col-xxl-12 col-xl-12 col-lg-12">
          <div className="postbox__details-wrapper pr-20">
            <article>
              <div className="postbox__thumb w-img wow animate__fadeInUp" data-wow-duration="1.1s">
              <Link to="/blog-details">
                <img src={articles.berita_img_xl} alt={articles.berita_judul}/>
              </Link>
              </div>
              <div className="postbox__details-title-box pb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
              <h4 className="postbox__details-title">{articles.berita_judul}</h4>
              <span class="post-meta">Admin .:. {articles.berita_tanggal}</span>
             </div>
              <div className="postbox__details-title-box pb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
              {Parser().parse(articles.berita_html)}
              </div>
              <div className="postbox__details tagcloud mb-50 wow animate__fadeInUp" data-wow-duration="1.1s">
                <span>Kategori:</span>
                <a href="#">{articles.berita_kategori}</a>
              </div>
             
            </article>
          </div>
        </div>
        
      </div>
    </div>
  </div>
  );
}

export default BlogDetails;
BlogDetails.defaultProps = {
	country: "in",
	pageSize: 6,
	category: "general",
};

BlogDetails.propTypes = {
	country: PropTypes.string,
	pageSize: PropTypes.number,
	category: PropTypes.string,
};


import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../assets/img/logo/logo-01.png';
import footerBG from '../../assets/img/footer/footer-bg.jpg';
function langganan(){

}

const Footer = (props) => {
    const { footerLogo, footerClass } = props;
    return (
        <footer>
            <div className={footerClass ? footerClass : 'footer__area footer__plr'} style={{backgroundImage: `url(${footerBG})`}}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-7 mb-70 wow animate__fadeInUp"
                            data-wow-duration="1.1s">
                            <div className="footer__widget footer-col-1">
                                <div className="footer__logo">
                                    <Link to="/">
                                        <img src={footerLogo ? footerLogo : Logo} alt="" />
                                    </Link>
                                </div>
                                <div className="footer__content">
                                <div className="footer__text ">
                                    <p className="text-small">Situs kami kini lebih segar dan modern. Kami telah merombak tampilan situs kami agar lebih mudah digunakan dan dinavigasi. Anda akan menemukan informasi yang Anda cari dengan lebih cepat dan nyaman. Lihat sendiri perbedaannya di sini</p>
                                </div>
                                
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-2 col-lg-6 col-md-6 col-sm-5 mb-70 wow animate__fadeInUp"
                            data-wow-duration="1.1s">
                            <div className="footer__widget footer-col-2">
                                <h4 className="footer__title">Layanan</h4>
                                <div className="footer__menu-list">
                                <ul>
                                    <li><a href="#">Satu Data</a></li>
                                    <li><a href="#">Open Data</a></li>
                                    <li><a href="#">Satu Peta</a></li>
                                    <li><a href="#">Dashboard Data</a></li>
                                    <li><a href="#">Webapi</a></li>
                                </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-2 col-lg-6 col-md-6 col-sm-5 mb-70 wow animate__fadeInUp"
                            data-wow-duration="1.1s">
                            <div className="footer__widget footer-col-3">
                                <h4 className="footer__title">
                                    <a href="#">Relasi</a>
                                </h4>
                                <div className="footer__menu-list">
                                    <ul>
                                        <li><a href="#">Satu Data Polewali Mandar</a></li>
                                        <li><a href="#">Satu Data Pasangkayu</a></li>
                                        <li><a href="#">Satu Data Majene</a></li>
                                        <li><a href="#">Satu Data Mamuju</a></li>
                                        <li><a href="#">Satu Data Mamasa</a></li>
                                        <li><a href="#">Satu Data Mamuju Tengah</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-7 mb-70 wow animate__fadeInUp"
                            data-wow-duration="1.1s">
                            <div className="footer__widget footer-col-4">
                                <h4 className="footer__title"><a href="#">Tetap update</a></h4>
                                <div className="footer__content">
                                <p>Bila berkenan kami akan memberikan informasi jika ada update ke email anda.</p>
                                </div>
                                <div className="footer__input">
                                    <form action="#">
                                        <input type="email" placeholder="Email anda" />
                                        <button className="subscribe-btn" onClick={langganan}>Berlangganan </button>
                                    </form>
                                </div>
                                <div className="footer__social-box">
                                    <span>Social Media:</span>
                                    <a href="#"><i className="fab fa-facebook-f"></i></a>
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                    <a href="#"><i className="fab fa-google-plus-g"></i></a>
                                    <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="copyright__area copyright__plr black-bg-3">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-6 wow animate__slideInUp" data-wow-duration="1.1s">
                            <div className="copyright__text mt-10">
                                <span>Copyright Portal Data Sulawesi Barat, All Right Reserved</span>
                            </div>
                            <p className='text-small'>Crafted by Tim13 Diskominfopers</p>
                        </div>
                        <div className="col-md-6 wow animate__fadeInUp" data-wow-duration="1.1s">
                           
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    );
}

export default Footer;